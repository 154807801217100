import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import SectionHeading from '../components/SectionHeading'
import { Section, InnerMedium } from '../components/Sections'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const InnerStyled = styled(InnerMedium)`
  padding-top: 0;
`

const PrivacyPage = ({ data, location: { pathname } }) => {
  const { html } = data.markdownRemark
  return (
    <Layout>
      <SEO
        title="プライバシーポリシー| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
        description="モバプロのプライバシーポリシーです。"
        pathName={pathname}
      />
      <SectionHeading withoutAnimation>
        プライバシーポリシー
      </SectionHeading>
      <Section>
        <InnerStyled>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </InnerStyled>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    markdownRemark(frontmatter: {title: {eq: "プライバシーポリシー"}}) {
      html
    }
  }
`

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

PrivacyPage.defaultProps = {
  location: null,
}

export default PrivacyPage
